// Override manually, because there's a window.Clipboard function in some browsers
import clipboard from 'clipboard';

/* eslint-disable */
import 'expose-loader?exposes=$,jQuery!jquery';
import 'expose-loader?exposes=ko!knockout';
import 'expose-loader?exposes=store!store';
import 'expose-loader?exposes=moment!moment';
/* eslint-enable */

import 'jquery-touch-events/src/jquery.mobile-events';
import 'knockout-postbox';

import 'knockout.validation';

import 'bootstrap-sass/assets/javascripts/bootstrap/dropdown';
import 'bootstrap-sass/assets/javascripts/bootstrap/collapse';
import 'bootstrap-sass/assets/javascripts/bootstrap/transition';
import 'bootstrap-sass/assets/javascripts/bootstrap/tab';
window.Clipboard = clipboard;
ko.options.deferUpdates = true;
ko.postbox.serializer = function (value) { return value; };
ko.validation.init({ insertMessages: false });
